var prodcat = prodcat || {};

prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};
(function ($) {
  Drupal.behaviors.productAddToBagV1 = {
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;
      $(context).off('click.addToBag').on('click.addToBag', '.js-product-add-to-bag:not(.js-product-add-all-to-bag)', function (e) {
        e.preventDefault();
        var $addToBagBtn = $(this);
        var skuBaseId = parseInt($addToBagBtn.attr('data-sku-base-id'));
        var quantity = parseInt($addToBagBtn.attr('data-qty'));
        var frequency = parseInt($addToBagBtn.attr('data-replenishment'));
        var skuData = prodcat.data.getSku(skuBaseId);
        var options = {};

        // Safety check for shopping status.
        if (!skuData.isShoppable) {
          return false;
        }

        // Init options with skubaseid.
        options.skuBaseId = skuBaseId;

        // If quantity provided, use it.
        if (!!quantity) {
          options.quantity = quantity;
        }

        // Replenishment
        if (typeof skuData !== 'undefined' && skuData.REFILLABLE && Drupal.settings.globals_variables && !!Drupal.settings.globals_variables.enable_auto_replenish_spp) {
          if (!!frequency) {
            options.REPLENISHMENT_FREQ = frequency;
          } else {
            options.REPLENISHMENT_FREQ = 0;
          }
          options.action = 'add';
          options.itemType = 'replenishment';
          options.add_to_cart = 1;
        }

        // Add to cart.
        prodcat.ui.addToCart(options);
      });

      $(context).off('click.addAllToBag').on('click.addAllToBag', '.js-product-add-all-to-bag', function (e) {
        e.preventDefault();
        var $addToBagBtn = $(this);
        var skuBaseIdAttribute = $addToBagBtn.attr('data-sku-base-id');
        var skuBaseIds = skuBaseIdAttribute ? skuBaseIdAttribute.split(',') : false;
        var quantity = parseInt($addToBagBtn.attr('data-qty'));
        var options = {};
        var skuData;

        if (!skuBaseIds || !skuBaseIds[0]) {
          return false;
        }
        skuData = prodcat.data.getSku(skuBaseIds[0]);
        // Safety check for shopping status.
        if (!skuData.isShoppable) {
          return false;
        }

        // Init options with skubaseid.
        options.skuBaseId = options.SKU_BASE_ID = (function () {
          var skus = [];

          skuBaseIds.forEach(function (sku) {
            skus.push(sku.trim());
          });

          return skus;
        })();
        // If quantity provided, use it.
        if (!!quantity) {
          options.quantity = quantity;
        }
        // Add to cart.
        prodcat.ui.addToCart(options);
      });
    },
    attached: false
  };

  // Update add to cart base id when sku changes.
  $(document).on('product.skuSelect', '.js-product-ui', function (e, skuBaseId) {
    var $product = $(this);
    var $addBtn = $('.js-product-add-to-bag', $product).filter(function () {
      return $(this).closest('.js-pdp-bundle-item').length < 1;
    });

    // Update sku base id on button.
    $addBtn.attr('data-sku-base-id', skuBaseId);
  });

  // Update add to cart base id when bundle skus change.
  $(document).on('product.bundleSelect', '.js-product-bundle', function (e, skuBaseIds) {
    var $productBundle = $(this);
    var $product = $productBundle.closest('.js-product-ui');
    var $addBtn = $('.js-product-add-to-bag', $product);

    // Update sku base id on button.
    $addBtn.attr('data-sku-base-id', skuBaseIds);
  });
})(jQuery);
